import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Router } from "@angular/router";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class Interceptor implements HttpInterceptor {
  constructor(private router: Router) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (localStorage.getItem("token")) {
      let id = 0;
      if (localStorage.getItem("user")) {
        id = JSON.parse(localStorage.getItem("user"))._id;
      }
      const tr = request.clone({
        setHeaders: {
          "token": localStorage.getItem("token"),
          "access-id": id.toString(),
          "deviceType": "Web"
        },
      });
      return next.handle(tr).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // console.log('event--->>>', event);
          }
          // if (event.type === HttpEventType.UploadProgress) {
          //   // This is an upload progress event. Compute and show the % done:
          //   const percentDone = Math.round(100 * event.loaded / event.total);
          //   localStorage.setItem('image_progress', percentDone + '');
          //   console.log(`File is ${percentDone}% uploaded.`);
          // }
          return event;
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401 && error instanceof HttpErrorResponse) {
            localStorage.clear();
            this.router.navigate(["/"]);
          }
          return throwError(error);
        })
      );
    } else {
      this.router.navigate(["/"]);

      const tr = request.clone({
        setHeaders: {
          "token": "",
          "deviceType": ""
        },
      });
      return next.handle(tr);
    }
  }
}

import { Component, OnInit } from "@angular/core";

export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  vendor: boolean;
}

export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: "nc-bank",
    class: "",
    vendor: false,
  },
  {
    path: "/product",
    title: "Products",
    icon: "nc-settings-gear-65",
    class: "",
    vendor: false,
  },
  {
    path: "/brand",
    title: "Brand",
    icon: "nc-settings-gear-65",
    class: "",
    vendor: false,
  },
  // {
  //   path: "/user",
  //   title: "User",
  //   icon: "nc-caps-small",
  //   class: "",
  // },
  {
    path: "/categories",
    title: "Categories",
    icon: "nc-paper",
    class: "",
    vendor: false,
  },
  {
    path: "/sub-categories",
    title: "Sub Categories",
    icon: "nc-paper",
    class: "",
    vendor: false,
  },
  // {
  //   path: "/sku",
  //   title: "SKU",
  //   icon: "nc-tile-56",
  //   class: "",
  //   vendor: false,
  // },
  {
    path: "/material",
    title: "Material",
    icon: "nc-shop",
    vendor: false,

    class: "",
  },
  {
    path: "/order",
    title: "Orders",
    icon: "nc-credit-card",
    class: "",
    vendor: false,
  },
  // {
  //   path: "/invitation",
  //   title: "Invitation",
  //   icon: "nc-credit-card",
  //   class: "",
  //   vendor: true,
  // },
  {
    path: "/user-chats",
    title: "User Chats",
    icon: "nc-icon nc-chat-33",
    class: "",
    vendor: true,
  },
  {
    path: "/slider",
    title: "Slider",
    icon: "nc-image",
    class: "",
    vendor: false,
  },
  // {
  //   path: "/member",
  //   title: "Members",
  //   icon: "nc-tile-56",
  //   class: "",
  // },
  // {
  //   path: "/setting",
  //   title: "App Setting",
  //   icon: "nc-settings-gear-65",
  //   class: "",
  // },
  // { path: "/table", title: "Table List", icon: "nc-tile-56", class: "" },
  // {
  //   path: "/typography",
  //   title: "Typography",
  //   icon: "nc-caps-small",
  //   class: "",
  // },
  // {
  //   path: "/upgrade",
  //   title: "Upgrade to PRO",
  //   icon: "nc-spaceship",
  //   class: "active-pro",
  // },
];

@Component({
  moduleId: module.id,
  selector: "sidebar-cmp",
  templateUrl: "sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  type: any = null;
  ngOnInit() {
    this.type = localStorage.getItem("type");
    this.menuItems = ROUTES.filter((menuItem) =>
      this.type == "V" ? menuItem.vendor === true : menuItem
    );
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vendor-login',
  templateUrl: './vendor-login.component.html',
  styleUrls: ['./vendor-login.component.scss']
})
export class VendorLoginComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

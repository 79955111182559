// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // url: "http://192.168.18.23:3000",
  url: "https://api.enaraty.xyz",
  firebaseConfig: {
    apiKey: "AIzaSyBIjIoHTRTiGy4tyrdzC3T0fFSodQaHtnw",
    authDomain: "ali-mama-4fef8.firebaseapp.com",
    projectId: "ali-mama-4fef8",
    storageBucket: "ali-mama-4fef8.appspot.com",
    messagingSenderId: "738933448314",
    appId: "1:738933448314:web:fdc8bc64ff485b6bbca5c3",
    measurementId: "G-L1ZLGRM7ME",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "./../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class BackendService {
  url = environment.url;
  chatUserData = {};
  constructor(private http: HttpClient) {}

  stats() {
    return this.http.post(this.url + "/user/stats", {});
  }
  getWholeSalers() {
    return this.http.get(this.url + "/user/dashboard/wholesalers");
  }
  login(data) {
    return this.http.post(this.url + "/user/admin/login", data);
  }
  getUsers() {
    return this.http.get(this.url + "/user");
  }
  changeUserStatus(data) {
    return this.http.put(this.url + "/user/change/status", data);
  }
  changeCatStatus(data) {
    return this.http.put(this.url + "/category/change/status", data);
  }
  getCategories() {
    return this.http.get(this.url + "/category/admin");
  }
  getAdminSubCategories() {
    return this.http.get(this.url + "/category/admin/subcategory");
  }
  getAllMainCategories() {
    return this.http.get(this.url + "/category/main/all");
  }
  getAllActiveMainCategories() {
    return this.http.get(this.url + "/category/main/allActive");
  }
  getSubCategories(id) {
    return this.http.post(this.url + "/category/sub/", id);
  }
  getAllSubCategories() {
    return this.http.get(this.url + "/category/sub/all");
  }

  getAllSubChildCategories() {
    return this.http.get(this.url + "/category/sub/child/all");
  }

  getSubChildCategories(id) {
    return this.http.post(this.url + "/category/sub/child/", id);
  }
  getAllOrders() {
    return this.http.get(this.url + "/order");
  }
  changeOrderStatus(data) {
    return this.http.put(this.url + "/order/change/status", data);
  }
  addCat(data) {
    return this.http.post(this.url + "/category", data);
  }
  editCat(data) {
    return this.http.put(this.url + "/category/update", data);
  }
  getSections() {
    return this.http.get(this.url + "/section");
  }
  addSect(data) {
    return this.http.post(this.url + "/section", data);
  }
  editSect(data) {
    return this.http.put(this.url + "/section/update", data);
  }
  changeSectionStatus(data) {
    return this.http.put(this.url + "/section/change/status", data);
  }
  getTransactions() {
    return this.http.get(this.url + "/history");
  }
  getPackages() {
    return this.http.get(this.url + "/package");
  }
  getCouponById(id) {
    let data = {
      id: id,
    };
    return this.http.post(this.url + "/coupon/find/", data);
  }
  getPackagesById(id) {
    return this.http.get(this.url + "/package/" + id);
  }
  getProducts() {
    return this.http.get(this.url + "/product/category/section/admin");
  }
  getAliBabaProductByLink(data) {
    return this.http.post(this.url + "/product/scrap/alibaba", data);
  }
  changeProductStatus(data) {
    return this.http.put(this.url + "/product/change/status", data);
  }

  addPackage(data) {
    return this.http.post(this.url + "/package", data);
  }
  editPackage(data) {
    return this.http.put(this.url + "/package/update", data);
  }
  changePackageStatus(data) {
    return this.http.put(this.url + "/package/change/status", data);
  }
  getCoups() {
    return this.http.get(this.url + "/coupon");
  }
  addCoup(data) {
    return this.http.post(this.url + "/coupon", data);
  }
  editCoup(data) {
    return this.http.put(this.url + "/coupon/update", data);
  }
  getSlider() {
    return this.http.get(this.url + "/slider");
  }
  addProduct(data) {
    return this.http.post(this.url + "/product/", data);
  }
  addUser(data) {
    return this.http.post(this.url + "/user/", data);
  }
  sendProductInvitation(data) {
    return this.http.post(this.url + "/product/invitation", data);
  }
  getProductInvitation() {
    return this.http.get(this.url + "/product/invitation");
  }
  editProduct(data) {
    return this.http.put(this.url + "/product/admin/update", data);
  }
  editUser(data) {
    return this.http.put(this.url + "/user/dashboard/update", data);
  }
  addSlider(data) {
    return this.http.post(this.url + "/slider", data);
  }
  deleteSlider(id) {
    return this.http.delete(this.url + "/slider/" + id);
  }
  deleteProduct(id) {
    return this.http.delete(this.url + "/product/" + id);
  }
  editSlider(data) {
    return this.http.put(this.url + "/slider/update", data);
  }
  getMember() {
    return this.http.get(this.url + "/member");
  }
  getMemberById(id) {
    return this.http.get(this.url + "/member/" + id);
  }
  getMemberAdmin() {
    return this.http.get(this.url + "/member/admin");
  }
  addMember(data) {
    return this.http.post(this.url + "/member", data);
  }
  editMember(data) {
    return this.http.put(this.url + "/member/update", data);
  }
  deleteMember(id) {
    return this.http.delete(this.url + "/member/" + id);
  }
  memberDetail(id) {
    return this.http.get(this.url + "/history/" + id);
  }
  changeMemberStatus(data) {
    return this.http.put(this.url + "/member/change/status", data);
  }
  addBrand(data) {
    return this.http.post(this.url + "/brand", data);
  }
  editBrand(data) {
    return this.http.put(this.url + "/brand/update", data);
  }
  deleteBrand(id) {
    return this.http.delete(this.url + "/brand/" + id);
  }
  getBrand() {
    return this.http.get(this.url + "/brand/");
  }
  addMaterial(data) {
    return this.http.post(this.url + "/material", data);
  }
  editMaterial(data) {
    return this.http.put(this.url + "/material/update", data);
  }
  deleteMaterial(id) {
    return this.http.delete(this.url + "/material/" + id);
  }
  getMaterial() {
    return this.http.get(this.url + "/material/");
  }
  addSKU(data) {
    return this.http.post(this.url + "/sku", data);
  }
  editSKU(data) {
    return this.http.put(this.url + "/sku/update", data);
  }
  deleteSKU(id) {
    return this.http.delete(this.url + "/sku/" + id);
  }
  getSKU() {
    return this.http.get(this.url + "/sku/");
  }
  changeSKUStatus(data) {
    return this.http.put(this.url + "/sku/change/status", data);
  }
  changeBrandStatus(data) {
    return this.http.put(this.url + "/brand/change/status", data);
  }
  changeMaterialStatus(data) {
    return this.http.put(this.url + "/material/change/status", data);
  }
  changeCoupStatus(data) {
    return this.http.put(this.url + "/coupon/change/status", data);
  }
  getSetting() {
    return this.http.get(this.url + "/setting");
  }
  updateKey(data) {
    return this.http.put(this.url + "/setting/change/key", data);
  }
  updateColors(data) {
    return this.http.put(this.url + "/setting/change/colors", data);
  }
  getStore() {
    return this.http.get(this.url + "/user/other/store/");
  }

  getProductIds() {
    return this.http.get(this.url + "/product/");
  }

  sendChatNotifications(userId: string, message: string) {
    return this.http.get(
      this.url + `/user/sendChatNotifications/${userId}/${message}`
    );
  }

  changeStoreStatus(data) {
    return this.http.put(this.url + "/store/change/status", data);
  }
}

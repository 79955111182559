import { Component, OnInit } from "@angular/core";
import { BackendService } from "src/app/services/backend.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  email: any;
  pwd: any;
  msg: any = "";
  loading: boolean = false;

  constructor(private api: BackendService, private router: Router) {}

  ngOnInit() {}

  async submit() {
    this.loading = true;
    if (!this.email || !this.pwd) {
      alert("Please enter credientails");
      this.loading = false;
      return;
    }

    try {
      let data = {
        email: this.email,
        password: this.pwd,
      };

      let res = await this.api.login(data).toPromise();
      console.log(res);

      let { success, msg } = res as any;
      if (success) {
        const { token, user } = res as any;
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("type", res["type"]);
        this.loading = false;
        this.router.navigate(["/dashboard"]);
      } else {
        this.msg = msg;
      }
    } catch (error) {
      console.log(error);
      // let {msg} = res as any;
      // this.msg = msg;
    }
    this.loading = false;
  }
}

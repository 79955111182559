import { Routes } from "@angular/router";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { LoginComponent } from "./components/login/login.component";
import { VendorLoginComponent } from './components/vendor-login/vendor-login.component';
import { LoginGuard } from "./services/login.guard";
import { AuthGuard } from "./services/auth.guard";

export const AppRoutes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", component: LoginComponent, canActivate: [LoginGuard] },
  {
    path: "", component: AdminLayoutComponent, canActivate: [AuthGuard],
    children: [{ path: "", loadChildren: "./layouts/admin-layout/admin-layout.module#AdminLayoutModule" }]
  },
  { path: "**", redirectTo: "dashboard" }
];
